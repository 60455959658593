import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col, Layout, Card, Alert, Radio } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../components/common/header";
import CommonFooter from "../components/common/footer";
import { PayPalButton } from "react-paypal-button-v2";

import {
  FreePlanList,
  ProPlanList,
  TeamPlanList,
} from "../components/plans/plans";

const { Content } = Layout;

const PaypalPage = () => {
  const [planPaypal, setPlanPaypal] = useState("monthly");

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setPlanPaypal(e.target.value);
  };

  const paypalSubscribeMonthlyPro = (data, actions) => {
    return actions.subscription.create({
      plan_id: "P-0U268571BS806183GMEOUZGI",
      // plan_id: "P-6TR0928153908061FMEOXIRY", **SANDBOX**
    });
  };
  const paypalSubscribeAnnuallyPro = (data, actions) => {
    return actions.subscription.create({
      plan_id: "P-6KF49433RA407973YMEOYXHQ",
    });
  };
  const paypalOnError = (err) => {
    console.log("Error");
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    console.log("PayPal approved");
    console.log(data.subscriptionID);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DayViewer Planner - Plans &amp; Pricing</title>
        <meta
          name="description"
          content="DAYVIEWER - Sign up if you are looking for an online calendar planner or a business team calendar and todo system at a competitive price, look no further than DayViewer."
        />
      </Helmet>
      <Layout>
        <CommonHeader />
      </Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <Content className="homepage-background">
          {/* <Alert
              message="New Users Promotion"
              description={
                <div>
                  New users automatically have DayViewer Pro enabled for 7 days
                  giving you time to evalute the potential of DayViewer in full.
                  <a href="/"> Sign Up Today</a>
                </div>
              }
              type="info"
              showIcon
              closable
            /> */}
          <Row>
            <Col>
              <div>
                <div>
                  <div className="homepage-headlines">
                    <div style={{ textAlign: "left !important" }}>
                      <h1>Plans &amp; Pricing</h1>
                      <p>
                        Get started with the Starter Plan -&gt; Go Pro to reach
                        your max potential.
                      </p>
                      <p>
                        All prices are in USD and exclude local tax (where
                        applicable, the rate varies dependant on residence).
                      </p>
                    </div>
                  </div>
                  <div>
                    <Card
                      style={{
                        width: "100%",
                        background: "#cdd5e673",
                        border: "none",
                      }}
                    >
                      <Row>
                        <Col xs={24} lg={8}>
                          <Card className="plan-cards">
                            <FreePlanList />
                          </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Card className="plan-cards">
                            <ProPlanList />
                          </Card>
                          <br />
                          <div>
                            <Radio.Group onChange={onChange} value={planPaypal}>
                              <Radio value="monthly">
                                <h3>Monthly - $8 / month</h3>
                              </Radio>
                              <Radio value="annually">
                                <h3>Annually - $69 / year</h3>
                              </Radio>
                            </Radio.Group>
                          </div>
                          <br />
                          <div>
                            <Col>
                              <h2>Selected: PRO {planPaypal.toUpperCase()}</h2>

                              <PayPalButton
                                options={{
                                  "client-id":
                                    "AZbGR9kWNjuRpG-uZZdQDIcdo7q7MdhMBvH9ltBh1f47vLpyB8TLDfgWvFSNBcZG4kJOA74sCPx_VzJ-",
                                  //"AbAN5fxJvY4Lv6-Ohek1OeyAQOxwPn_JO7f5miZLBuwW-lFRKmmbh_i6ApY9SOfjqo2yoGHRxEQ4HRw_", ***SANDBOX***
                                  vault: "true",
                                  intent: "subscription",
                                }}
                                createSubscription={
                                  planPaypal === "monthly"
                                    ? paypalSubscribeMonthlyPro
                                    : paypalSubscribeAnnuallyPro
                                }
                                onApprove={paypalOnApprove}
                                catchError={paypalOnError}
                                onError={paypalOnError}
                                onCancel={paypalOnError}
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Card className="plan-cards">
                            <TeamPlanList />
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  <div />
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
      <Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export default PaypalPage;
